<template>
  <div class="archive">
    <div class="title">文件归档</div>
    <div class="archive-list" v-if="archiveInfo.archiveTime">
      <div class="list-left">归档时间:</div>
      <div>{{ archiveInfo.archiveTime || '-' }}</div>
    </div>
    <div class="archive-list">
      <div class="list-left">文件类型:</div>
      <div>{{ archiveInfo.fileTypeName || '-' }}</div>
    </div>
    <div class="archive-list" v-if="props.isArchiveManage">
      <div class="list-left">档案编号:</div>
      <div v-if="!archiveInfo.number">无</div>
      <div v-else>{{ archiveInfo.number || '-' }}</div>
      <a style="margin-left:10px;color:#1890FF" @click="clickNumber()"> <FormOutlined /> 编辑 </a>
    </div>
    <div class="archive-list">
      <div class="list-left">用印后文件:</div>
      <div v-if="archiveInfo.hasArchivePermission" class="filedown">
        <div v-for="item in archiveInfo.documentArchiveFileList" :key="item.id" class="filedown-list">
          <div class="filedown-left">
            <img
              height="44px"
              width="44px"
              :src="getUrl(item.fileName, item.fullFile, item.suffix).url"
              class="filedown-img"
              v-if="
                item.suffix == 'pdf' ||
                  item.suffix == 'docx' ||
                  item.suffix == 'xls' ||
                  item.suffix == 'doc' ||
                  item.suffix == 'xlsx'
              "
            />
            <a-image :src="getUrl(item.fileName, item.fullFile).url" class="filedown-img" v-else />
            <div class="filedown-img-down" @click="downloadFile(item.fullFile)">
              <i class="iconfont icon-xiazai icons" style="color:white;font-size:12px;"></i>
            </div>
          </div>
          <div class="filedown-right">
            <div class="text-box">
              <a-tooltip placement="bottomLeft" v-if="item.fileName?.length > 25">
                <template #title>
                  <span>{{ item.fileName }}.{{ item.suffix }}</span>
                </template>
                {{ item.fileName.substring(0, 25) ?? '-' }}...
              </a-tooltip>
              <span v-else>{{ item.fileName ?? '-' }}.{{ item.suffix }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="color: rgb(178, 177, 177);">无权查看</div>
    </div>
    <div class="inner-container" v-if="!props.isArchiveManage && route.path !== '/seal/applyArchiveList/detail'">
      <div class="list-left">归档备注:</div>
      <div v-if="archiveInfo.documentArchiveRemarkList?.length > 0" class="flex-item">
        <div v-for="(item, index) in archiveInfo.documentArchiveRemarkList" :key="index">
          <div class="arch-remark">{{ item.remark }}</div>
        </div>
      </div>
      <div v-else>无</div>
    </div>
    <div class="inner-container" v-if="props.isArchiveManage || route.path === '/seal/applyArchiveList/detail'">
      <div class="list-left">归档备注:</div>
      <div v-if="archiveInfo.documentArchiveRemarkList?.length > 0" class="flex-item">
        <div v-for="(item, index) in archiveInfo.documentArchiveRemarkList" :key="index">
          <div class="arch-remark">{{ item.remark }}</div>
          <div class="archive-list-remark">
            <div>
              {{ item.staffName }}
            </div>
            <div style="margin-left:10px">
              {{ item.createTime }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>无</div>
    </div>
    <div
      style="margin-left:70px"
      v-if="archiveInfo.documentArchiveRemarkList?.length < 10 && props.isArchiveManage"
      @click="remarkvisible = true"
    >
      <a style="color:#1890FF"> <FormOutlined /> 补充归档备注 </a>
    </div>
    <div class="archive-list">
      <div class="list-left">补充附件:</div>
      <div>
        <div
          v-if="archiveInfo.hasArchivePermission && archiveInfo.documentArchiveAttachmentList.length > 0"
          class="filedown"
        >
          <div v-for="item in archiveInfo.documentArchiveAttachmentList" :key="item.id" class="filedown-list">
            <div class="filedown-left">
              <img
                height="44px"
                width="44px"
                :src="getUrl(item.fileName, item.fullFile, item.suffix).url"
                class="filedown-img"
                v-if="
                  item.suffix == 'pdf' ||
                    item.suffix == 'docx' ||
                    item.suffix == 'xls' ||
                    item.suffix == 'doc' ||
                    item.suffix == 'xlsx'
                "
              />
              <a-image v-else :src="getUrl(item.fileName, item.fullFile).url" class="filedown-img" />
              <div class="filedown-img-down" @click="downloadFile(item.fullFile)">
                <i class="iconfont icon-xiazai icons" style="color:white;font-size:12px;"></i>
              </div>
            </div>
            <div class="filedown-right">
              <div class="text-box">
                <a-tooltip placement="bottomLeft" v-if="item.fileName?.length > 25">
                  <template #title>
                    <span>{{ item.fileName }}.{{ item.suffix }}</span>
                  </template>
                  {{ item.fileName.substring(0, 25) ?? '-' }}
                </a-tooltip>
                <span v-else>{{ item.fileName ?? '-' }}.{{ item.suffix }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="archiveInfo.hasArchivePermission && archiveInfo.documentArchiveAttachmentList.length == 0">无</div>
        <div v-if="!archiveInfo.hasArchivePermission" style="color: rgb(178, 177, 177);">无权查看</div>
        <div>
          <a-upload
            v-if="
              archiveInfo.documentArchiveAttachmentList &&
                archiveInfo.documentArchiveAttachmentList?.length < 100 &&
                props.isArchiveManage
            "
            class="upload-btn"
            v-model:file-list="fileList"
            name="file"
            :action="action + '?width=88&height=88'"
            :show-upload-list="false"
            withCredentials
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <a-button class="btn" :loading="pageLoading">
              <img
                style="margin-right: 4px; vertical-align: middle; transform: translateY(-1px);"
                src="@/assets/svg/upload.svg"
              />
              上传文件
            </a-button>
          </a-upload>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    @ok="remarkOk"
    @cancel="remarkonCancel"
    centered
    title="补充归档备注"
    v-model:visible="remarkvisible"
    v-if="remarkvisible"
    :confirmLoading="confirmLoading"
  >
    <a-form class="opinion-form" ref="formRef" :rules="remarkrules" :model="form">
      <a-form-item name="remark" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" label="归档备注">
        <a-textarea
          :autoSize="{ minRows: 6 }"
          v-model:value.trim="form.remark"
          placeholder="请输入归档备注"
          :maxlength="500"
          showCount
          class="opinion-textarea"
          @input="
            form.remark = form.remark.replace(
              /[^\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9《》\/！“”（）【】<>\/!''()\[\]-]+/g,
              ''
            )
          "
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    @ok="numberOk"
    @cancel="numberonCancel"
    centered
    title="编辑档案编号"
    v-model:visible="numbervisible"
    v-if="numbervisible"
    :confirmLoading="confirmLoading"
  >
    <a-form class="opinion-form" ref="formRef" :rules="numberrules" :model="form">
      <a-form-item name="number" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" label="档案编号">
        <a-input
          v-model:value.trim="form.number"
          placeholder="请输入档案编号"
          :maxlength="60"
          showCount
          @input="numberChange"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { ref, defineProps, onMounted, computed, reactive, watch, defineEmits } from 'vue'
import { useRoute } from 'vue-router'
import { DownloadOutlined, FormOutlined } from '@ant-design/icons-vue'
import { cmsNotice } from '@/utils/utils'
import { getArchiveDetail, putArchiveRemark } from '@/apis/archive'
const props = defineProps(['archiveInfos', 'isArchiveManage', 'id'])
const emits = defineEmits(['reArchive'])
const route = useRoute()
const archiveInfo = ref({})
const remarkvisible = ref(false)
const numbervisible = ref(false)
const formRef = ref(null)
const action = process.env.VUE_APP_API_BASE_URL + '/file/upload'
const fileList = ref()
const pageLoading = ref(false)
const confirmLoading = ref(false)
const form = reactive({
  remark: undefined,
  number: undefined,
  fileIds: undefined
})
const remarkrules = {
  remark: [
    {
      required: true,
      message: '请输入归档备注',
      trigger: 'blur'
    }
  ]
}
const numberrules = {
  number: [
    {
      required: true,
      message: '请输入档案编号',
      trigger: 'blur'
    }
  ]
}

watch([() => props.archiveInfos], ([newProp1], [oldProp1]) => {
  // 在这里可以访问新旧值并执行你想要的响应式操作
  archiveInfo.value = newProp1
  // archiveInfo.value = newProp1
})
// const getArchive = () => {
//   console.log('没有获取到传参,于是靠documentId获取归档信息', route.query.documentId)
//   let id = route.query.documentId
//   //   console.log('id', id)
//   //   hasArchivePermission
//   getArchiveDetail(id).then(res => {
//     console.log('归档信息', res)
//     archiveInfo.value = res.data.archiveInfo
//   })
// }
onMounted(() => {
  console.log('9999999999999', props.archiveInfos, props.isArchiveManage, props.id)
  if (props.archiveInfos) {
    archiveInfo.value = props.archiveInfos
    form.number = archiveInfo.value.number
  } else {
    // getArchive()
  }
})
const clickNumber = () => {
  numbervisible.value = true
  form.number = archiveInfo.value.number
}
const beforeUpload = file => {
  return new Promise((resolve, reject) => {
    const suffix = file.name.split('.').pop()
    const size = Math.floor(file.size / (1024 * 1024))
    let type = ['pdf', 'doc', 'docx', 'jpeg', 'jpg', 'png', 'webp', 'xls', 'xlsx', 'doc', 'docx']
    if (size > 50) {
      // Notification['error']({
      //   message: '提示',
      //   description: `大小不能超过${state.size}M`
      // })
      cmsNotice('error', `大小不能超过50M`)
      return reject(false)
    }
    if (file.size <= 6) {
      console.log(file.size, 'size')
      cmsNotice('error', `大小应大于6个字节`)
      return reject(false)
    }
    if (!type.includes(suffix)) {
      // Notification['error']({
      //   message: '提示',
      //   description: `格式错误，仅支持${type.join(',')}格式`
      // })
      cmsNotice('error', `格式错误，仅支持${type.join(',')}格式`)
      return reject(false)
    }
    return resolve(true)
  })
}
const handleChange = info => {
  console.log('info', info)
  try {
    const {
      file: { status, response }
    } = info
    switch (status) {
      case 'uploading':
        //处理加载
        pageLoading.value = true
        console.log('处理加载', loading.value)
      case 'done':
        // 处理上传成功
        if (response && response.code === 0) {
          console.log('state.fileList', response.data)
          form.fileIds = response.data[0].fileIds
          const params = {
            id: props.id,
            fileIds: response.data.map(item => item.id)
          }
          putArchiveRemark(params).then(res => {
            if (res.success) {
              ;(remarkvisible.value = false), cmsNotice('success', '添加成功')
              emits('reArchive')
            }
          })
          // form.fileIds.push(response.data.id)
        }
        pageLoading.value = false
        break
      case 'removed':
        // 处理错误
        console.log('state.fileList', fileList.value)
        cmsNotice('error', '添加失败')
        pageLoading.value = false
        break
    }
  } catch (error) {
    console.log('err', error)
  }
}
const downloadFile = src => {
  window.location.href = src.replace('.com//', '.com/') + '?upload=true'
  cmsNotice('success', '正在为你下载，请耐心等待')
}
const remarkonCancel = () => {
  form.remark = ''
  remarkvisible.value = false
}
const numberonCancel = () => {
  form.number = ''
  numbervisible.value = false
}
const remarkOk = () => {
  confirmLoading.value = true
  formRef.value
    .validate()
    .then(() => {
      const params = {
        id: props.id,
        remark: form.remark
      }
      putArchiveRemark(params).then(res => {
        if (res.success) {
          ;(remarkvisible.value = false), cmsNotice('success', '备注添加成功')
          emits('reArchive')
          confirmLoading.value = false
          form.remark = ''
        }
      })
    })
    .catch(error => {
      console.log('校验失败', error)
      confirmLoading.value = false
    })
}
const numberOk = () => {
  confirmLoading.value = true
  formRef.value
    .validate()
    .then(() => {
      const params = {
        id: props.id,
        number: form.number
      }
      putArchiveRemark(params).then(res => {
        if (res.success) {
          ;(numbervisible.value = false), cmsNotice('success', '档案编号修改成功')
          emits('reArchive')
          confirmLoading.value = false
        }else{
          confirmLoading.value = false
        }
      })
    })
    .catch(error => {
      console.log('校验失败', error)
      confirmLoading.value = false
    })
}
const numberChange = () => {
  form.number = form.number.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+/g, '')
}
const getUrl = computed(() => (file, filename, suffix) => {
  const list = [
    {
      type: ['docx', 'doc'],
      url: require('@/assets/images/word.png')
    },
    {
      type: ['xls', 'xlsx'],
      url: require('@/assets/images/excel.png')
    },
    {
      type: ['pdf'],
      url: require('@/assets/images/pdf.png')
    }
  ]
  let url
  for (let index = 0; index < list.length; index++) {
    if (list[index].type.includes(suffix)) {
      url = list[index].url
    }
  }
  if (!url) {
    return { url: filename, img: true }
  }
  return { url: url, img: false }
})
</script>
<style lang="scss" scoped>
.title {
  height: 40px;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.list-left {
  margin-right: 10px;
}

.flex-item {
  flex-grow: 1;
  width: 80%;
}

.archive-list-remark {
  color: rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 14px;
}

.archive-list {
  display: flex;
  margin-top: 5px;
}
.inner-container {
  display: flex;
  flex-wrap: wrap;
}

.archive {
  background-color: #fff;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  padding: 20px;
}

.filedown {
  width: 460px;
  border: 1px solid #f2f0f0;
}

.filedown-list {
  display: flex;
}

.filedown-list:hover .filedown-img-down {
  display: block;
  /* 父元素悬停时显示子元素 */
}

.filedown-left {
  position: relative;
  width: 46px;
  height: 46px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin: 10px;

  .filedown-img {
    width: 40px;
    height: 40px;
    margin: 2px;
    overflow: hidden;
  }

  .filedown-img-down {
    display: none;
    position: absolute;
    bottom: 0;
    width: 44px;
    height: 15px;
    color: white;
    line-height: 15px;
    background-color: #00000072;
    text-align: center;
  }
}

.filedown-right {
  line-height: 44px;
  margin: 10px;
}

.opinion-form {
  :deep(.opinion-textarea) {
    position: relative;
    width: 400px;
    &::after {
      position: absolute;
      bottom: 0;
      right: 11px;
    }
  }
  :deep(.ant-form-item) {
    margin-bottom: 0;
  }
}

.filedown {
  margin: 10px;
  margin-left: 0;
}

.base-apply-list {
  min-height: calc(100vh - 180px);
}

.loading-animation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-btn {
  :deep(.ant-upload, .img-area) {
    height: 36px;
  }
}
</style>
